import React from "react"

import Layout from "../components/layout"
import Navigation from "../components/navigation"
import SEO from "../components/seo"
import Footer from "../components/footer"

import flowlogo from "../images/logos/flowlogo.png"

const PrivacyPolicy = () => (
  <Layout>
    <SEO 
      title="Flow - Privacy Policy" 
      description="Flow goes straight to the source of the largest social platforms in the world - to match people with property" 
      image={flowlogo}
    />
    <Navigation />

    <div className="container mx-auto text-sm mt-10 leading-relaxed">
    
      <h2 className="font-bold text-lg mt-10 text-xl">
        PRIVACY POLICY AND NOTICE
      </h2>

      <p className="text-sm text-gray-700 mt-2">
        IF USER ARE READING THESE TERMS AS A RESULT OF CLICKING A FACEBOOK OR INSTAGRAM AD, WHICH IS SUGGESTING THE T&C’S ARE THAT OF AN ESTATE AGENT/LANDLORD OR FLOW PARTNER, IT IS BECAUSE THE ESTATE AGENT/LANDLORD OR FLOW PARTNER IS USING THE PLATFORM IN WHICH EVENT THESE TERMS APPLY.
      </p>
      <p className="text-sm text-gray-700 mt-2">
        This is the privacy statement of Flow. In this document, “we” or “us” refer to Flow Living Proprietary Limited (“Flow / the Platform”).
      </p>
      <p className="text-sm text-gray-700 mt-2">
        This is a statement to tell User our policy about all information that we record about User. It covers both information that could identify User and information that could not.
      </p>
      <p className="text-sm text-gray-700 mt-2">
        We are extremely concerned to protect User privacy and confidentiality. Our policy is both specific and strict. It complies with the South African law. If User think our policy falls short of User expectations or that we are failing to abide by our policy, <b>do please tell us</b>.
      </p>
      <p className="text-sm text-gray-700 mt-2">
        We regret that if there are one or more points below with which User are not happy, User only recourse is to refrain from registering for membership and to leave our website immediately.
      </p>
      <p className="text-sm text-gray-700 mt-2">
        Except as set out below, we do not share, or sell, or disclose to a third party, any personally identifiable information collected at this site.
      </p>
      <p className="text-sm text-gray-700 mt-2">
        Here is a list of the information we collect from User, either through our website or because User give it to us when registering, and why it is necessary to collect it:
    </p>

    <h3 className="font-bold text-lg text-lg mt-10">
      1. Business, financial and personal information
    </h3>

    <p className="text-sm text-gray-700 mt-2">
    This includes; amongst others; a User’s identification and contact information who register an account.
    </p>
    <p className="text-sm text-gray-700 mt-2">
    Information collected also includes all information given to us in the course of engaging with the Platform.
    </p>
    <p className="text-sm text-gray-700 mt-2">
    We undertake to preserve the confidentiality of the information and of the terms of our relationship. It is not used for any purpose other than providing all Flow services. We expect User to reciprocate this policy.
    </p>

    <ol className="list-decimal list-inside text-gray-700 mt-3">
      <li className="mt-2">This information is used for amongst others:</li>
      <li className="mt-2">
        <ol className="list-decimal list-inside text-gray-700 mt-3 ml-5">
          <li className="mt-2">to provide User with the services which User request;</li>
          <li className="mt-2">for verifying User identity for security purposes;</li>
          <li className="mt-2">for introducing Users to Estate Agents/Landlords and Flow Partners and vice versa and facilitating communication and User entering into a direct contract with each other;</li>
          <li className="mt-2">for marketing our Flow services and products;</li>
          <li className="mt-2">information which does not identify any User specifically may be used in a general way by us or third parties, to provide class information allowing retargeting and the like.</li>
        </ol>
      </li>
    </ol>

    <h3 className="font-bold text-lg text-lg mt-10">
      2. Flow Partner Marketplace information
    </h3>

    <p className="text-sm text-gray-700 mt-2">When we obtain information from User specifically via the Flow Partner Marketplace, said information is passed onto the Flow Partner and may also be used for retargeting purposes.</p>

    <h3 className="font-bold text-lg text-lg mt-10">
      3. User domain name and email address
    </h3>

    <p className="text-sm text-gray-700 mt-2">This information is recognised by our servers and the pages that the User visits and communications are recorded. We shall not under any circumstances, divulge User email address to any person who is not an employee or contractor of ours or member or service provider with whom User are transacting and who does not need to know, either generally or specifically. This information is used:</p>

    <ol className="list-decimal list-inside text-gray-700 mt-3">
      <li className="mt-2">to correspond with User or deal with User as User expects, either directly or with Estate Agent/Landlords or Flow Partners with whom User is transacting;</li>
      <li className="mt-2">in a collective way not referable to any particular individual, for the purpose of quality control and improvement of our service offerings;</li>
      <li className="mt-2">to send User news about the services to which User have signed up and/or given consent;</li>
      <li className="mt-2">to tell User about Flow’s other services or transactional information from Flow.</li>
    </ol>

    <h3 className="font-bold text-lg text-lg mt-10">
      4. Information User post on or through our platform
    </h3>

    <p className="text-sm text-gray-700 mt-2">Information User sends to us via Flow’s owned channels or communicating within the Flow Platform, is stored on our servers. We do not specifically use that information except to allow it to be read by intended recipients or, but User will see in our terms and conditions that we reserve a right to use it in any way we decide.</p>
    
    <h3 className="font-bold text-lg text-lg mt-10">
      5. Platform usage information
    </h3>

    <p className="text-sm text-gray-700 mt-2">When User enters a lead form on Facebook, Instagram or via any Flow Platform page, we collect the data User have given to us in order to obtain confirmation that User wishes their information to pass to the Platform and on to an Estate Agent/Landlord or Flow Partner in order to provide to User the service the User requires. We record Users request and our reply in order to increase the efficiency of our business. We keep any personally identifiable information the User has supplied the Platform, such as User name, email address, telephone number.</p>
    
    <h3 className="font-bold text-lg text-lg mt-10">
      6. Content User provide to us with a view to be used by third party
    </h3>

    <p className="text-sm text-gray-700 mt-2">If User provide information to us with a view to it being read, copied, downloaded or used by other people, we accept no responsibility for what that third party may do with it. It is up to User to satisfy itself about the privacy level of every person who might see User information.</p>

    <h3 className="font-bold text-lg text-lg mt-10">
      7. Cookies
    </h3>

    <p className="text-sm text-gray-700 mt-2">Cookies are small text files that are placed on User computer's hard drive through User web browser when User visit any website. They are widely used to make websites work, or work more efficiently, as well as to provide information to the owners of the site.</p>

    <p className="text-sm text-gray-700 mt-2">Like all other users of cookies, we may request the return of information from User computer when User browser requests a web page from our server. Cookies enable our web server to identify User to us, and to track User actions and the pages User visit while User uses our website. The cookies we use may last for a single visit to our site (they are deleted from User computer when User close User browser), or may remain on User computer until User deletes them or until a defined period of time has passed.</p>

    <p className="text-sm text-gray-700 mt-2">Although User browser software enables User to disable cookies, we recommend that User allow the use of cookies in order to take advantage of the features of our website that rely on their use. If User prevents their use, User will not be able to use all the functionality of our website. Here are the ways we use cookies:</p>

    <ol className="list-decimal list-inside text-gray-700 mt-3">
      <li className="mt-2">to record whether User has accepted the use of cookies on our website. This is solely to comply with the law. If User have chosen not to accept cookies, we will not use cookies for User visit, but unfortunately, our Platform will not work well for User.</li>
      <li className="mt-2">to allow essential parts of the Platform to operate for User.</li>
      <li className="mt-2">to operate the online notification form - the form that User use to contact us for any reason. This cookie is set on User arrival at our website and deleted when User close User browser.</li>
      <li className="mt-2">to enhance security on our contact form. It is set for use only through the contact form. This cookie is deleted when User close User browser.</li>
      <li className="mt-2">to collect information about how visitors use our Platform. We use the information to improve User experience of our Platform and enable us to increase leads for Estate Agents/Landlords and Flow Partners. This cookie collects information in an anonymous form, including the number of visitors to the site, where visitors have come to the site from, and the pages they visited.</li>
      <li className="mt-2">to store User personal information so that User does not have to provide it afresh when User visits the site next time. This cookie will last for [90] days.</li>
    </ol>

    <h3 className="font-bold text-lg text-lg mt-10">
      8. Re-marketing
    </h3>

    <p className="text-sm text-gray-700 mt-2">We use re-marketing. This involves Facebook, Instagram, Google or some other supplier placing a tag or marker on User in order to be able to serve to User an advert for our products, Partners and services when User visit some other websites.</p>

    <h3 className="font-bold text-lg text-lg mt-10">
      9. Disclosure to Government and their agencies
    </h3>

    <p className="text-sm text-gray-700 mt-2">We are subject to the law like everyone else. We may be required to give information to legal authorities if they so request or if they have the proper authorisation such as a search warrant or court order.</p>

    <h3 className="font-bold text-lg text-lg mt-10">
      10. Compliance with the law
    </h3>

    <p className="text-sm text-gray-700 mt-2">This confidentiality policy has been compiled so as to comply with the law of every jurisdiction in which we aim to do business. If User thinks it fails to satisfy the law of User country, we should like to hear from User, but ultimately it is Users choice as to whether User wishes to use our platform and services. <b>If User does not agree with our Policies then we encourage User to not use our Platform.</b></p>

    <h3 className="font-bold text-lg text-lg mt-10">
      11. Removal of User information
    </h3>

    <p className="text-sm text-gray-700 mt-2">If User wishes us to remove personally identifiable information from our platform after termination of User membership User may contact us at info@flowliving.com. To better safeguard User information, we will also take reasonable steps to verify User identity before granting access or making corrections to User information.</p>

    <h3 className="font-bold text-lg text-lg mt-10">
      12. Data may be “processed” outside South Africa
    </h3>

    <p className="text-sm text-gray-700 mt-2">Our platforms are global cloud servers. We also use outsourced services in countries outside South Africa from time to time in other aspects of our business. Accordingly, data obtained within South Africa may be “processed” outside South Africa and data obtained in any other country may be processed within or outside that country.</p>

    <p className="text-sm text-gray-700 mt-2">If User have any questions regarding the privacy policy, please contact us through the contact page.</p>

          

       
    </div>

    <Footer />
  </Layout>
)

export default PrivacyPolicy
